// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CryptoPrices from "./components/CryptoPrices";
import FilamentCostCalc from "./components/Calculator";

function App() {
  return (
    <Router>
      <div className="App">
        {/* <nav>
          <ul>
            <li>
              <Link to="/">(Crypto Prices)</Link>
            </li>
            <li>
              <Link to="/calc">Filament Cost Calculator</Link>
            </li>
          </ul>
        </nav> */}

        <Routes>
          <Route path="/" element={<CryptoPrices />} />
          <Route path="/calc" element={<FilamentCostCalc />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
